<template>
  <j-card title="Mailbox" class="mt-4">
    <!-- Mailbox Form -->
    <j-card-text>
      <!-- Error Messages -->
      <j-alert
        v-if="error"
        type="error"
        :message="error"
        class="my-2"
      />

      <!-- Unauthorized Mailbox -->
      <j-alert
        v-else-if="!mailbox.loading && !mailbox.authorized"
        class="my-2"
        message="Authorize to continue..."
      />

      <!-- Mailbox Configuration -->
      <div v-else>
        <j-form model="staff.mailbox">

          <!-- Processed Opps Label -->
          <j-control name="processed_opp_label" cols="6" />

          <!-- Errors Label -->
          <j-control name="error_opp_label" cols="6" />

        </j-form>

        <j-form
          v-for="label in mailboxLabels"
          :key="label.labelKey"
          :model="label"
        >
          <j-control
            name="label"
            :options="mailbox.get('account_labels')"
            cols="6"
          />
          <j-control
            name="section_slug"
            cols="5"
          />
          <!-- Remove Import -->
          <v-col cols="1">
            <j-button
              type="icon"
              icon="times"
              title="Remove import label"
              @action="handleRemoveLabel(label)"
            />
          </v-col>
        </j-form>
      </div>
    </j-card-text>

    <!-- Actions -->
    <template #actions>

      <!-- Authorize -->
      <j-button
        v-if="!mailbox.authorized || mailbox.expired"
        label="Authorize Mailbox"
        :processing="mailbox.saving"
        @action="handleAuthorize"
      />

      <!-- Save -->
      <j-button
        v-else
        label="Save Changes"
        :processing="mailbox.saving"
        :disabled="mailbox.pristine"
        @action="handleSave"
      />
    </template>

    <!-- Options -->
    <template #options>

      <!-- Add Label -->
      <j-button
        v-if="mailbox.authorized && !mailbox.expired"
        label="Add Import Label"
        type="add"
        @action="handleAddLabel"
      />

    </template>
  </j-card>
</template>

<script>

// Google Auth
import Vue from 'vue'
import GAuth from 'vue-google-oauth2'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions, mapGetters } = createNamespacedHelpers('staff')

export default {
  name: 'StaffMailbox',
  // -------
  // Data ==
  // -------
  data() {
    return {
      configuring: false,
      error: ''
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'contact',
      'mailbox'
    ]),
    ...mapGetters([
      'hasMailbox'
    ]),
    mailboxLabels() {
      return this.mailbox.labels.getModels().filter((label) => !label.get('_destroy'))
    }
  },
  // ------------
  // created() ==
  // ------------
  async mounted() {
    // Get mailbox
    await this.fetchMailbox()
    // Check expiration
    if (this.mailbox.get('expired')) {
      this.error = 'Token expired. Please authorize the mailbox again.'
    }
    // Don't initialize Google Auth if we're in development
    if (process.env.NODE_ENV == 'development') { return }

    // Initialize Google Auth
    const gauthOption = await this.mailbox.oauth2()
    Vue.use(GAuth, gauthOption)
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'fetchMailbox',
      'createMailbox',
      'disableMailbox'
    ]),
    labelName (label) {
      return label ? label.split(':')[1] : 'Undefined'
    },
    handleCreate () {
      this.createMailbox()
    },
    handleDisable () {
      this.disableMailbox()
    },
    async handleAuthorize () {
      // Reset error
      this.error = ''
      // Get code from Google
      const authCode = await this.$gAuth.getAuthCode().catch((error) => { console.error(error) })
      // Send code to back end for conversion to tokens
      this.mailbox.authorize(authCode)
        .catch(() => {
          this.error = 'Authentication failed'
        })
    },
    handleSave () {
      // Save mailbox
      this.mailbox.save()
      // Set configuring flag
      // this.configuring = false
    },
    handleAddLabel () {
      this.mailbox.addLabel()
    },
    handleRemoveLabel(label) {
      label.set('_destroy', true)
    }
  }
}
</script>
